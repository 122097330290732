// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-complete-js": () => import("./../../../src/pages/contact-complete.js" /* webpackChunkName: "component---src-pages-contact-complete-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-privacy-js": () => import("./../../../src/pages/contact-privacy.js" /* webpackChunkName: "component---src-pages-contact-privacy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-itech-chat-cc-ask-1-js": () => import("./../../../src/pages/itech_chat_cc_ask1.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-1-js" */),
  "component---src-pages-itech-chat-cc-ask-10-js": () => import("./../../../src/pages/itech_chat_cc_ask10.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-10-js" */),
  "component---src-pages-itech-chat-cc-ask-11-js": () => import("./../../../src/pages/itech_chat_cc_ask11.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-11-js" */),
  "component---src-pages-itech-chat-cc-ask-12-js": () => import("./../../../src/pages/itech_chat_cc_ask12.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-12-js" */),
  "component---src-pages-itech-chat-cc-ask-13-js": () => import("./../../../src/pages/itech_chat_cc_ask13.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-13-js" */),
  "component---src-pages-itech-chat-cc-ask-14-js": () => import("./../../../src/pages/itech_chat_cc_ask14.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-14-js" */),
  "component---src-pages-itech-chat-cc-ask-2-js": () => import("./../../../src/pages/itech_chat_cc_ask2.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-2-js" */),
  "component---src-pages-itech-chat-cc-ask-3-js": () => import("./../../../src/pages/itech_chat_cc_ask3.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-3-js" */),
  "component---src-pages-itech-chat-cc-ask-4-js": () => import("./../../../src/pages/itech_chat_cc_ask4.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-4-js" */),
  "component---src-pages-itech-chat-cc-ask-5-js": () => import("./../../../src/pages/itech_chat_cc_ask5.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-5-js" */),
  "component---src-pages-itech-chat-cc-ask-6-js": () => import("./../../../src/pages/itech_chat_cc_ask6.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-6-js" */),
  "component---src-pages-itech-chat-cc-ask-7-js": () => import("./../../../src/pages/itech_chat_cc_ask7.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-7-js" */),
  "component---src-pages-itech-chat-cc-ask-8-js": () => import("./../../../src/pages/itech_chat_cc_ask8.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-8-js" */),
  "component---src-pages-itech-chat-cc-ask-9-js": () => import("./../../../src/pages/itech_chat_cc_ask9.js" /* webpackChunkName: "component---src-pages-itech-chat-cc-ask-9-js" */),
  "component---src-pages-itech-chat-operator-js": () => import("./../../../src/pages/itech_chat_operator.js" /* webpackChunkName: "component---src-pages-itech-chat-operator-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mvv-js": () => import("./../../../src/pages/mvv.js" /* webpackChunkName: "component---src-pages-mvv-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-service-bicycle-js": () => import("./../../../src/pages/service/bicycle.js" /* webpackChunkName: "component---src-pages-service-bicycle-js" */),
  "component---src-pages-service-bike-js": () => import("./../../../src/pages/service/bike.js" /* webpackChunkName: "component---src-pages-service-bike-js" */),
  "component---src-pages-service-camera-js": () => import("./../../../src/pages/service/camera.js" /* webpackChunkName: "component---src-pages-service-camera-js" */),
  "component---src-pages-service-gate-js": () => import("./../../../src/pages/service/gate.js" /* webpackChunkName: "component---src-pages-service-gate-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-service-internet-js": () => import("./../../../src/pages/service/internet.js" /* webpackChunkName: "component---src-pages-service-internet-js" */),
  "component---src-pages-service-lockless-js": () => import("./../../../src/pages/service/lockless.js" /* webpackChunkName: "component---src-pages-service-lockless-js" */),
  "component---src-pages-service-qtnet-js": () => import("./../../../src/pages/service/qtnet.js" /* webpackChunkName: "component---src-pages-service-qtnet-js" */),
  "component---src-pages-service-qtnetservice-booking-js": () => import("./../../../src/pages/service/qtnetservice/booking.js" /* webpackChunkName: "component---src-pages-service-qtnetservice-booking-js" */),
  "component---src-pages-service-qtnetservice-index-js": () => import("./../../../src/pages/service/qtnetservice/index.js" /* webpackChunkName: "component---src-pages-service-qtnetservice-index-js" */),
  "component---src-pages-service-qtnetservice-numberpay-js": () => import("./../../../src/pages/service/qtnetservice/numberpay.js" /* webpackChunkName: "component---src-pages-service-qtnetservice-numberpay-js" */),
  "component---src-pages-service-qtnetservice-registration-js": () => import("./../../../src/pages/service/qtnetservice/registration.js" /* webpackChunkName: "component---src-pages-service-qtnetservice-registration-js" */),
  "component---src-pages-service-remote-js": () => import("./../../../src/pages/service/remote.js" /* webpackChunkName: "component---src-pages-service-remote-js" */),
  "component---src-pages-service-smartpay-js": () => import("./../../../src/pages/service/smartpay.js" /* webpackChunkName: "component---src-pages-service-smartpay-js" */),
  "component---src-pages-service-support-js": () => import("./../../../src/pages/service/support.js" /* webpackChunkName: "component---src-pages-service-support-js" */),
  "component---src-pages-service-ticketlessgate-js": () => import("./../../../src/pages/service/ticketlessgate.js" /* webpackChunkName: "component---src-pages-service-ticketlessgate-js" */),
  "component---src-pages-service-unit-js": () => import("./../../../src/pages/service/unit.js" /* webpackChunkName: "component---src-pages-service-unit-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

